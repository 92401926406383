import * as React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/mixins';
import { Breadcrumbs } from '../../breadcrumbs';
import { filterFeaturedPosts, getLatestUpdatesPost, getPopularPost } from '../utils';

import { ArticlesSection } from './blog-articles';
import { BlogFeatured } from './blog-featured/blog-featured';
import { BlogHero } from './blog-hero/blog-hero';
import { BlogMostPopular } from './blog-most-popular/blog-most-popular';
import { BlogSeo } from './blog-seo';

interface BlogPageType {
	posts: [];
}

export const BlogPage: React.FC<BlogPageType> = ({ posts }) => {
	const latestUpdatesPost = React.useMemo(() => getLatestUpdatesPost(posts), []);
	const featuredPost = React.useMemo(() => filterFeaturedPosts(posts), []);
	const popularPost = React.useMemo(() => getPopularPost(posts), []);

	return (
		<BlogWrap>
			<BlogSeo />
			<Container>
				<Breadcrumbs slug={'/blog'} />
				<BlogHero post={latestUpdatesPost} />
			</Container>

			<SecondaryWrap>
				<Container>
					<BlogFeatured posts={featuredPost} />
				</Container>
			</SecondaryWrap>

			<Container>
				<ArticlesSection posts={posts} />
			</Container>

			<SecondaryWrap>
				<Container>
					<BlogMostPopular posts={popularPost} />
				</Container>
			</SecondaryWrap>
		</BlogWrap>
	);
};

const BlogWrap = styled.div`
	& [data-style='title'] {
		margin-bottom: 16px;
		line-height: 32px;
		color: ${(p) => p.theme.colors.gray5};
	}

	& [data-padding-style='form'] {
		padding: 80px 0;
	}

	& [data-padding-style='form'] {
		padding: 80px 0;
	}

	${media.md`
		& [data-style='title'] {
      line-height: 40px;
    }
  `}

	${media.xl`
		${(p) => css`
			& [data-style='title'] {
				margin-bottom: 24px;
				font-size: ${p.theme.typography.sizes.h3};
				line-height: 48px;
			}
		`}
  `}

	${media.xxl`
		& [data-padding-style='form'] {
			padding: 84px 0;
		}
  `}
`;

const Container = styled.div`
	padding-left: var(--section-sm-horizontal-padding);
	padding-right: var(--section-sm-horizontal-padding);

	${media.md`
    padding-left: var(--section-md-horizontal-padding);
    padding-right: var(--section-md-horizontal-padding);
  `}

	${media.xl`
    padding-left: var(--section-xl-horizontal-padding);
    padding-right: var(--section-xl-horizontal-padding);
		margin: 0 auto;
  `}

  ${media.xxl`
    padding-left: var(--section-xxl-horizontal-padding);
    padding-right: var(--section-xxl-horizontal-padding);

		max-width: 1460px;
  `}
`;

const SecondaryWrap = styled.section`
	background: ${(p) => p.theme.colors.backgroundGray};
	padding: 80px 0;
	position: relative;

	${media.md`
		padding: 120px 0;
	`}

	${media.xl`
		padding: 160px 0;
	`}
`;
