import * as React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { media } from '../../../../styles/mixins';
import { RemixIcon } from '../../../shared/remix-icon';
import { Typography } from '../../../shared/typography';
import { formatDateToUsFormat } from '../../utils';

interface BlogHeroType {
	post: any;
}

export const BlogHero: React.FC<BlogHeroType> = ({ post }) => {
	const {
		title,
		feature_image,
		feature_image_alt,
		custom_excerpt,
		primary_tag,
		created_at,
		reading_time,
		slug,
	} = post;

	const formattedData = formatDateToUsFormat(created_at);

	return (
		<HeroWrap>
			<Typography data-style="title" as="h2">
				Latest updates
			</Typography>
			<Link to={`/blog/${slug}/`}>
				<PostCard>
					<CardImg src={feature_image} alt={feature_image_alt} />
					<CardContent>
						<CardHeader>
							<Typography data-style="tag">{primary_tag?.name}</Typography>
							<Typography data-style="name" as="h3">
								{title}
							</Typography>
							<Typography data-style="excerpt" as="p">
								{custom_excerpt}
							</Typography>
						</CardHeader>

						<CardFooter>
							<Typography data-style="features-item">{formattedData}</Typography>
							<Typography data-style="features-item">
								<FeaturesRemixIcon className="ri-time-line" />
								{reading_time} min read
							</Typography>
						</CardFooter>
					</CardContent>
				</PostCard>
			</Link>
		</HeroWrap>
	);
};

const HeroWrap = styled.section`
	margin: 16px 0 64px;

	a {
		color: initial;
	}

	${media.md`
    margin: 32px 0 84px;
	`}

	${media.xl`
		margin: 100px 0 160px;
  `}
`;

const PostCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	${media.lg`
    flex-direction: row;
	`}
`;

const CardImg = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
	min-height: 240px;

	${media.md`
    min-height: 440px;
  `}

	${media.lg`
    min-height: 460px;
    max-width: 773px;
  `}

	${media.xxl`
    min-height: 600px;
    max-width: 1048px;
	`}
`;

const CardContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 0 24px;

	${media.lg`
    justify-content: space-between;
    margin: 24px 0;
    max-width: 343px;
	`}

	${media.xxl`
    max-width: 480px;
	`}
`;

const CardHeader = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	& [data-style='tag'],
	& [data-style='excerpt'] {
		color: ${(p) => p.theme.colors.gray6};
	}

	& [data-style='tag'] {
		text-transform: uppercase;
		font-weight: 500;
		line-height: 16px;
	}

	& [data-style='name'] {
		font-size: ${(p) => p.theme.typography.sizes.t4};
	}

	& [data-style='excerpt'] {
		font-size: ${(p) => p.theme.typography.sizes.t2};
		line-height: 16px;
	}

	${media.md`
    ${(p) => css`
			& [data-style='tag'] {
				font-size: ${p.theme.typography.sizes.t2};
				line-height: 20px;
			}

			& [data-style='name'] {
				font-size: ${p.theme.typography.sizes.h5};
				font-weight: 600;
				line-height: 28px;
			}

			& [data-style='excerpt'] {
				font-size: ${p.theme.typography.sizes.t3};
				line-height: 22px;
			}
		`}
  `}

	${media.lg`
    ${(p) => css`
			[data-style='name'] {
				font-size: ${p.theme.typography.sizes.h4};
				line-height: 32px;
			}
		`}
  `}
`;

const CardFooter = styled.div`
	display: flex;
	justify-content: space-between;

	& [data-style='features-item'] {
		color: ${(p) => p.theme.colors.gray6};
		display: flex;
		gap: 5px;
		line-height: 16px;
	}

	${media.md`
    ${(p) => css`
			& [data-style='features-item'] {
				font-size: ${p.theme.typography.sizes.t2};
				line-height: 20px;
			}
		`}
  `}
`;

const FeaturesRemixIcon = styled((p) => <RemixIcon {...p} />)`
	color: ${(p) => p.theme.colors.gray6};
	font-size: 16px;
`;
