import * as React from 'react';
import styled, { css } from 'styled-components';

import { ComponentGeneralFaq } from '@schema';

import { media } from '../../../../styles/mixins';

import { AccordionItem } from './accordion-item';

interface PostFAQProps {
	faq: ComponentGeneralFaq[];
}

export const PostFAQ: React.FC<PostFAQProps> = ({ faq }) => {
	return (
		<>
			{faq.length > 0 ? (
				<Section>
					<Title>FAQ</Title>
					<Container>
						{faq.map((item) => (
							<AccordionItem faq={item} key={item.id} />
						))}
					</Container>
				</Section>
			) : (
				<></>
			)}
		</>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`;

const Title = styled.p`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h3};
	`}
	font-weight: 600;
	line-height: 48px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	position: relative;
	height: fit-content;
	width: fit-content;
	&::after {
		content: '';
		position: absolute;
		bottom: -5px;
		left: 0;
		height: 4px;
		width: 70%;
		background: ${(p) => p.theme.gradients.orange1};
	}
	${media.md`
		line-height: 72px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
	`}
	${media.xl`
		line-height: 88px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
	`}
`;

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 40px 0;
	${media.md`
		gap: 48px;
		padding: 60px 0;
	`}
	${media.xl`
		gap: 250px;
		flex-direction: row;
		padding: 80px 0;
	`}
`;
