import * as React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../../../styles/mixins';
import { RemixIcon } from '../../../shared/remix-icon';

interface PostSocialType {
	title: string;
}

export const PostSocial: React.FC<PostSocialType> = ({ title }) => {
	const [url, setUrl] = React.useState<string>('');

	React.useLayoutEffect(() => {
		if (typeof window !== 'undefined') {
			setUrl(window.location.href);
		}
	}, []);

	return (
		<PostSocialWrap>
			<ShareWrap>
				<RemixIcon className="ri-share-forward-fill" />
				<span>Share</span>
			</ShareWrap>
			<SocialList>
				<SocialItem>
					<a
						href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<SocialRemixIcon className="ri-facebook-fill" />
					</a>
				</SocialItem>
				<SocialItem>
					<a
						href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<SocialRemixIcon className="ri-linkedin-fill" />
					</a>
				</SocialItem>
				<SocialItem>
					<a
						href={`https://twitter.com/intent/tweet?text=${title}&url=${url}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<SocialRemixIcon className="ri-twitter-fill" />
					</a>
				</SocialItem>
			</SocialList>
		</PostSocialWrap>
	);
};

const PostSocialWrap = styled.div`
	width: 164px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	${media.xl`
	  width: auto;
    gap: 24px;
  `}
`;

const ShareWrap = styled.div`
	display: flex;
	gap: 9px;
	color: ${(p) => p.theme.colors.gray6};

	span {
		font-size: ${(p) => p.theme.typography.sizes.t3};
		line-height: 22px;

		${media.xl`
      &:first-child {
        font-size: 20px;
      }
    `}
	}

	${media.xl`
		flex-direction: column;
    align-items: center;
  `}
`;

const SocialList = styled.ul`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${media.xl`
		flex-direction: column;
    gap: 16px;
  `}
`;

const SocialItem = styled.li`
	background: ${(p) => p.theme.gradients.orangeLast};
	width: fit-content;
	border-radius: 50%;
	${transition('background')};

	a {
		color: ${(p) => p.theme.colors.text};
		border-radius: inherit;
		width: 44px;
		height: 44px;
	}

	&:hover {
		background: ${(p) => p.theme.gradients.orangeLast};
		${transition('background')};
	}

	${media.xl`
    ${(p) => css`
			background: ${p.theme.colors.gray};

			a {
				width: 32px;
				height: 32px;
			}
		`}
  `}
`;

const SocialRemixIcon = styled((p) => <RemixIcon {...p} />)`
  font-size: 24px;

  ${media.xl`
    font-size: 18px;
  `}
}`;
