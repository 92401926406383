import * as React from 'react';
import styled from 'styled-components';

import { BlogPost } from '@schema';

import { media } from '../../../styles/mixins';
import { Breadcrumbs } from '../../breadcrumbs';
import { HTML } from '../../shared/html';
import { SubscribeForm } from '../../subscribe-form';

import { PostContent } from './post-content';
import { PostFAQ } from './post-faq/post-faq';
import { PostHeadings } from './post-headings/post-headings';
import { PostHero } from './post-hero/post-hero';
import { PostSocial } from './post-social/post-social';
import { PostSeo } from './post-seo';

interface PostBodyType {
	post: BlogPost;
}

export const PostBody: React.FC<PostBodyType> = ({ post }) => {
	const {
		blogContent,
		title,
		meta: { url },
		faq,
	} = post;
	const headers = JSON.parse(post.blogContent).blocks.filter(
		(item) => item.type === 'header' && item.data.level === 2,
	);

	return (
		<article>
			<PostSeo post={post} />
			<Container>
				<Breadcrumbs slug={url} />
			</Container>
			<PostHero post={post} />
			<Container>
				<SectionWrap>
					<ContentWrap>
						<PostSocial title={title} />
						<PostContent>
							<HTML content={blogContent}></HTML>
						</PostContent>
					</ContentWrap>
					<AsideWrap>
						{headers.length > 0 && <PostHeadings headers={headers} />}
						<FormWrap>
							<SubscribeForm variant="small" />
						</FormWrap>
					</AsideWrap>
				</SectionWrap>

				<PostFAQ faq={faq} />
			</Container>

			<SecondaryWrap data-padding-style="form">
				<Container>
					<SubscribeForm variant="big" />
				</Container>
			</SecondaryWrap>
		</article>
	);
};

const Container = styled.div`
	padding-left: var(--section-sm-horizontal-padding);
	padding-right: var(--section-sm-horizontal-padding);

	${media.md`
    padding-left: var(--section-md-horizontal-padding);
    padding-right: var(--section-md-horizontal-padding);
  `}

	${media.xl`
    padding-left: var(--section-xl-horizontal-padding);
    padding-right: var(--section-xl-horizontal-padding);
		margin: 0 auto;
  `}

  ${media.xxl`
    padding-left: var(--section-xxl-horizontal-padding);
    padding-right: var(--section-xxl-horizontal-padding);

		max-width: 1460px;
  `}
`;

const SectionWrap = styled.section`
	position: relative;
	display: flex;
	gap: 40px;
	margin-bottom: 80px;

	${media.md`
		margin-bottom: 130px;
  `}

	${media.xl`
		margin-bottom: 160px;
  `}
`;

const AsideWrap = styled.aside`
	position: sticky;
	height: 100%;
	top: 100px;
	display: none;

	${media.xl`
		display: block;
  `}
`;

const FormWrap = styled.div`
	width: 385px;
	background: ${(p) => p.theme.colors.superLightGray};
	padding: 24px;
	margin-top: 24px;

	${media.xxl`
    width: 440px;
	`};
`;

const ContentWrap = styled.div`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	gap: 40px;
	width: 100%;

	${media.xl`
		flex-direction: row;
		align-items: flex-start;
  `}
`;

const SecondaryWrap = styled.section`
	background: ${(p) => p.theme.colors.backgroundGray};
	padding: 80px 0;
	position: relative;

	${media.md`
		padding: 120px 0;
	`}

	${media.xl`
		padding: 160px 0;
	`}
`;
