import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { media, transition } from '../../../../styles/mixins';

export interface PostHeadingProps {
	headers?: any;
}

export const PostHeadings: React.FC<PostHeadingProps> = ({ headers }) => {
	const [activeId, setActiveId] = React.useState(null);

	return (
		<PostWrap>
			<OListWrap>
				{headers.map((heading, id) => (
					<li key={id} className={id === activeId ? 'active' : ''}>
						<Link onClick={() => setActiveId(id)} to={`#header-${id}`}>
							{heading.data.text.replaceAll('&nbsp;', '')}
						</Link>
					</li>
				))}
			</OListWrap>
		</PostWrap>
	);
};

const PostWrap = styled.nav`
	width: 385px;
	background: ${(p) => p.theme.colors.superLightGray};
	padding: 24px;

	ol,
	ul {
		display: flex;
		flex-direction: column;
		padding: 0;
	}

	li,
	a {
		font-weight: 400;
		color: ${(p) => p.theme.colors.gray7};
		${transition('color')};

		&:hover {
			color: ${(p) => p.theme.colors.gray5};
			${transition('color')};
		}
	}

	li.active,
	li.active > a {
		color: ${(p) => p.theme.colors.orange1};
		${transition('color')};
	}

	${media.xxl`
    width: 440px;
	`};
`;

const OListWrap = styled.ol`
	margin: 0 0 0 28px;
	gap: 16px;
	list-style: none;

	& > li {
		margin-left: -13px;
	}
`;
