import * as React from 'react';
import { navigate } from 'gatsby';

import {
	BlogPost,
	CookieBannerEntityResponse,
	FooterEntityResponse,
	HeaderEntityResponse,
	MenuEntityResponse,
} from '@schema';

import { PostBody } from '../../components/blog';
import MainLayout from '../../layouts/main-layout';

interface PostTemplateProps {
	pageContext: {
		menu: MenuEntityResponse;
		header: HeaderEntityResponse;
		footer: FooterEntityResponse;
		post: BlogPost;
		cookieBanner: CookieBannerEntityResponse;
		theme: string;
	};
}

const PostTemplate = (props: PostTemplateProps) => {
	const { menu, header, footer, post, cookieBanner, theme } = props.pageContext;

	if (!post) {
		return navigate('/404');
	}

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="visible"
			theme={theme}
		>
			<PostBody post={post} />
		</MainLayout>
	);
};

export default PostTemplate;
