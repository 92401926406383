import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../../../styles/mixins';
import { HTML } from '../../../shared/html';

export const AccordionItem = ({ faq }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Item>
			<Title data-is-open={isOpen && 'true'} onClick={() => setIsOpen(!isOpen)}>
				{faq.question}
				<Symbol data-is-open={isOpen && 'true'}>
					{isOpen ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="4"
							viewBox="0 0 20 4"
							fill="none"
						>
							<path d="M0.666992 0.666748H19.3337V3.33342H0.666992V0.666748Z" fill="#F86B00" />
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
						>
							<path d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z" fill="#1F1D1E" />
						</svg>
					)}
				</Symbol>
			</Title>
			<Content>
				<HTML content={faq.answer} />
			</Content>
		</Item>
	);
};

const Item = styled.details`
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.colors.superLightGray};
`;

const Title = styled.summary`
	display: flex;
	flex-direction: row;
	gap: 24px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	font-weight: 400;
	line-height: 24px;
	color: ${(p) => p.theme.colors.headerText};
	padding: 16px;
	box-sizing: border-box;
	justify-content: space-between;
	${transition('all')};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
	    font-weight: 600;
	    line-height: 32px;
        letter-spacing: 0.01em;
    `}
	path {
		${transition('all')};
	}
	cursor: pointer;
	&[data-is-open='true'] {
		color: ${(p) => p.theme.colors.orange1};
	}
	&:hover {
		color: ${(p) => p.theme.colors.orange1};
		path {
			fill: ${(p) => p.theme.colors.orange1};
		}
	}
`;

const Content = styled.div`
	p {
		padding: 0 16px 16px 16px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		font-weight: 400;
		line-height: 22px;
		color: ${(p) => p.theme.colors.gray5};
		${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
	    line-height: 24px;
    `}
	}
`;

const Symbol = styled.p`
	display: flex;
	align-items: center;
	height: 24px;
	${media.md`
        margin-top: 5px;
    `}
`;
