import * as React from 'react';
import { Helmet } from 'react-helmet';

import { BlogPost } from '@schema';

interface PostSeoProps {
	post: BlogPost;
}

export const PostSeo: React.FC<PostSeoProps> = ({ post }) => {
	const {
		title,
		description,
		meta,
		heroSectionImage,
		blog_post_creators,
		publishedAt,
		updatedAt,
		faq,
	} = post;
	const replaceQuotes = (text) => text.replaceAll('"', "'");
	return (
		<Helmet>
			<html lang="en" />
			<title>{meta.title}</title>
			<meta name="description" content={meta.description} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={heroSectionImage?.data?.attributes?.url} />
			<script type="application/ld+json">
				{`
					{
						"@context": "http://schema.org",
						"@type": "BlogPosting",
						"mainEntityOfPage": {
							"@type": "WebPage",
							"@id": "https://www.softkit.dev/${meta.url}"
						},
						"headline": "${title}",
						"description": "${description}",
						"image": "${heroSectionImage?.data?.attributes?.url}",
						"author": {
							"@type": "Person",
							"name": "${
								blog_post_creators?.data?.[0]?.attributes &&
								blog_post_creators?.data?.[0].attributes.firstName +
									' ' +
									blog_post_creators?.data?.[0].attributes.lastName
							}"
						},
						"publisher": {
							"@type": "Organization",
							"@name": "Softkit",
							"logo": {
								"@type": "ImageObject",
								"url": "https://www.softkit.dev/static/images/new_logo_big_dcafad35d3_fc5759104e.svg?updated_at=2023-03-27T15:37:54.251Z",
								"width": "163",
								"height": "38"
							}
						},
						"datePublished": "${publishedAt}",
						"dateModified": "${updatedAt}"
					}
				`}
			</script>
			<script type="application/ld+json">
				{`
					{
						"@context": "http://schema.org",
						"@type": "FAQPage",
						"mainEntity": [${faq.map(
							(detail) => `{
								"@type": "Question",
								"name": "${replaceQuotes(detail.question)}",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "${replaceQuotes(JSON.parse(detail.answer).blocks[0].data.text)}"
								}
							}`,
						)}]
					}
				`}
			</script>
		</Helmet>
	);
};
