import * as React from 'react';
import styled, { css } from 'styled-components';

import { BlogPost } from '@schema';

import { media } from '../../../../styles/mixins';
import { SkImage } from '../../../shared/image';
import { RemixIcon } from '../../../shared/remix-icon';
import { Typography } from '../../../shared/typography';
import { formatDateToUsFormat } from '../../utils';

interface PostHeroType {
	post: BlogPost;
}

export const PostHero: React.FC<PostHeroType> = ({ post }) => {
	const { title, heroSectionImage, minutesToRead, blog_post_creators, blogPostTags, updatedAt } =
		post;

	const formattedData = formatDateToUsFormat(updatedAt);

	return (
		<Component>
			<ImageWrapper>
				<SkImage
					url={heroSectionImage?.data?.attributes?.url}
					alt={heroSectionImage?.data?.attributes?.alternativeText}
				/>
			</ImageWrapper>
			<BackgroundWrapper>
				<Content>
					<TextWrapp>
						<FeaturesList>
							<Typography data-style="features-item" as="li">
								<FeaturesRemixIcon className="ri-calendar-2-line" />
								{formattedData}
							</Typography>
							<Typography data-style="features-item" as="li">
								<FeaturesRemixIcon className="ri-time-line" />
								{minutesToRead} min read
							</Typography>
						</FeaturesList>
						<Typography data-style="title" as="h1">
							{title}
						</Typography>
						<TagsWrap>
							{blogPostTags?.data && (
								<Tags>
									{blogPostTags.data.map((tag) => (
										<Tag key={tag.attributes.tag}>
											<Typography data-style="tag">{tag.attributes.tag}</Typography>
										</Tag>
									))}
								</Tags>
							)}
						</TagsWrap>
					</TextWrapp>
					{blog_post_creators?.data && (
						<AuthorsWrap>
							<Authors>
								{blog_post_creators.data.map(
									({ attributes: { firstName, lastName, logo, position } }) => (
										<Author key={lastName}>
											<AuthorAvatarWrapper>
												<SkImage
													url={logo.data.attributes.url}
													alt={logo.data.attributes.alternativeText}
												/>
											</AuthorAvatarWrapper>
											<AuthorDetail>
												<AuthorName>{`${firstName} ${lastName}`}</AuthorName>
												<AuthorJob>{position}</AuthorJob>
											</AuthorDetail>
										</Author>
									),
								)}
							</Authors>
						</AuthorsWrap>
					)}
				</Content>
			</BackgroundWrapper>
		</Component>
	);
};

const Component = styled.div`
	position: relative;
	height: 100vh;
	max-height: 680px;
	display: flex;

	margin-top: 16px;
	margin-bottom: 60px;

	${media.md`
    margin-bottom: 80px;
  `}

	${media.xl`
		margin-bottom: 100px;
  `}
`;

const ImageWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	& > img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;

const BackgroundWrapper = styled.div`
	height: 100%;
	width: 100%;
	background: ${(p) => p.theme.colors.headerBlack};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 1;
`;

const Content = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-left: var(--section-sm-horizontal-padding);
	padding-right: var(--section-sm-horizontal-padding);
	color: ${(p) => p.theme.colors.white};

	${media.md`
		padding-left: var(--section-md-horizontal-padding);
		padding-right: var(--section-md-horizontal-padding);
	`}

	${media.xl`
		padding-left: var(--section-xl-horizontal-padding);
		padding-right: var(--section-xl-horizontal-padding);

		margin: 0 auto;
	`};

	${media.xxl`
		padding-left: var(--section-xxl-horizontal-padding);
		padding-right: var(--section-xxl-horizontal-padding);

		max-width: 1460px;
	`};
`;

const TextWrapp = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	& [data-style='title'] {
		font-size: ${(p) => p.theme.typography.sizes.t5};
		line-height: 28px;

		${media.md`
			${(p) => css`
				font-size: ${p.theme.typography.sizes.t6};
				line-height: 40px;
			`}
		`}

		${media.xl`
			${(p) => css`
				font-size: ${p.theme.typography.sizes.h3};
				line-height: 48px;
			`}
		`}
	}

	& [data-style='tag'] {
		width: max-content;
		border-radius: 50px;
		line-height: 16px;
		background: ${(p) => p.theme.colors.transparentWhite};
		padding: 6px 12px;

		${media.md`
			${(p) => css`
				font-size: ${p.theme.typography.sizes.t3};
				line-height: 22px;
				padding: 7px 16px;
			`}
		`}
	}
`;

const FeaturesList = styled.ul`
	display: flex;
	gap: 17px;

	& [data-style='features-item'] {
		display: flex;
		gap: 5px;
		font-size: ${(p) => p.theme.typography.sizes.t2};
		line-height: 20px;
	}

	${media.md`
		gap: 25px;
	`}
`;

const FeaturesRemixIcon = styled((p) => <RemixIcon {...p} />)`
	color: ${(p) => p.theme.colors.white};
	font-size: 20px;
`;
const TagsWrap = styled.div`
	overflow-x: auto;
	margin-bottom: 24px;
`;

const Tags = styled.ul`
	display: flex;
	gap: 8px;
	width: fit-content;
`;

const Tag = styled.li``;

const AuthorsWrap = styled.div`
	overflow-x: auto;
`;

const Authors = styled.div`
	width: max-content;
	display: flex;
	gap: 16px;
	margin-bottom: 24px;

	${media.xl`
		margin-bottom: 40px;
	`}
`;

const Author = styled.div`
	display: flex;
	align-items: center;
`;

const AuthorAvatarWrapper = styled.div`
	height: 44px;
	width: 44px;
	overflow: hidden;
	border-radius: 50%;

	img {
		max-width: 100%;
		height: auto;
	}
`;

const AuthorDetail = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 8px;

	p,
	h6 {
		width: fit-content;
	}

	${media.md`
		padding-left: 12px;
	`}
`;

const AuthorName = styled.h6`
	font-size: ${(p) => p.theme.typography.sizes.t3};
	line-height: 22px;
	margin: 0;

	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
			line-height: 24px;
		`}
	`}

	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
			line-height: 28px;
		`}
	`}
`;

const AuthorJob = styled.p`
	font-size: ${(p) => p.theme.typography.sizes.t2};

	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
			line-height: 22px;
		`}
	`}
`;
