import * as React from 'react';
import styled, { css } from 'styled-components';

import { useEventListener, useTheme } from '@hooks';
import { isNotSSR } from '@utils';

import { media, transition } from '../../../../styles/mixins';

import '@fontsource/work-sans';

export interface PostContentProps {
	children?: string | JSX.Element | JSX.Element[];
}

export const PostContent: React.FC<PostContentProps> = ({ children }) => {
	const contentRef = React.useRef(null);

	const headerHeightTheme = useTheme().common.header.height;
	const [headerHeight, setHeaderHeight] = React.useState<string>(
		headerHeightTheme.desktopIncreased,
	);
	useEventListener('scroll', () => {
		if (window.scrollY > 0) {
			setHeaderHeight(headerHeightTheme.lg);
		} else {
			setHeaderHeight(headerHeightTheme.desktopIncreased);
		}
	});

	React.useLayoutEffect(() => {
		if (contentRef.current) {
			contentRef.current.querySelectorAll('a').forEach((link) => {
				link.setAttribute('target', '_blank');
				link.setAttribute('rel', 'noopener referrer');
			});
		}
	}, []);

	React.useLayoutEffect(() => {
		if (isNotSSR) {
			const elements = document.getElementsByTagName('h2');
			for (let i = 0; i < elements.length; i++) {
				elements[i].setAttribute('id', `${'header-' + i}`);
			}
			const { hash } = window.location;
			const headingToScroll = document.getElementById(hash?.replace('#', ''));

			if (!headingToScroll) return;

			window.scroll({ top: headingToScroll.offsetTop - Number(headerHeight.slice(0, -2)) });
		}
	}, []);

	return (
		<PostWrap headerHeight={headerHeight} ref={contentRef}>
			{children}
		</PostWrap>
	);
};

const PostWrap = styled.div<{ headerHeight: string }>`
	color: ${(p) => p.theme.colors.gray5};
	max-width: 655px;

	ul,
	ol {
		padding-left: var(--section-sm-horizontal-padding);
	}

	ul {
		display: block;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 40px;
	}

	p,
	li {
		color: ${(p) => p.theme.colors.gray5};
	}

	li::before {
		top: 14px;
	}

	p > span {
		display: inline;
	}

	a {
		color: ${(p) => p.theme.colors.orange1};
		position: relative;

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			transform: scaleX(0);
			height: 1px;
			bottom: 5px;
			left: 0;
			background-color: ${(p) => p.theme.colors.orange1};
			transform-origin: bottom right;
			${transition('transform', 'ease-in-out', '0.4s')};
		}

		&:hover:after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}

	em a {
		font-style: italic;
	}

	p,
	a,
	li {
		font-size: ${(p) => p.theme.typography.sizes.t3};
		line-height: 22px;
		font-weight: 300;
		font-family: 'Work Sans', sans-serif;
	}

	h2,
	h3 {
		display: inline-block;
		scroll-margin-top: calc(16px + ${(p) => p.headerHeight});
		font-family: 'Work Sans', sans-serif;

		// for safari
		// padding-top: calc(16px + ${(p) => p.headerHeight});
		// margin-top: calc(-1 * (16px + ${(p) => p.headerHeight}));
	}

	h2 {
		margin: 40px 0 16px 0;
	}

	h3,
	h4,
	h5,
	h6 {
		margin: 16px 0 4px 0;
	}

	b {
		font-weight: 600;
		margin: 20px 0 5px 0;
		display: block;
	}

	h2 {
		font-size: 22px;
	}

	h3 {
		font-size: ${(p) => p.theme.typography.sizes.t5};
		line-height: 22px;
		font-weight: 600;
	}

	h4 {
		font-size: ${(p) => p.theme.typography.sizes.h6};
		line-height: 22px;
		font-weight: 600;
	}

	h5 {
		font-size: ${(p) => p.theme.typography.sizes.t3};
		line-height: 20px;
		font-weight: 600;
	}

	figure {
		margin: 32px 0;
	}

	figcaption {
		color: ${(p) => p.theme.colors.gray7};
		font-size: ${(p) => p.theme.typography.sizes.t1};
		line-height: 16px;
		margin-top: 8px;
		text-align: end;
	}

	hr {
		visibility: collapse;
		margin-bottom: 32px;
	}

	img {
		width: 100%;
		height: auto;
		margin: 32px 0;
		${media.md`
			margin: 40px 0;
		`}
		${media.xl`
			margin: 60px 0;
		`}
	}

	blockquote {
		color: ${(p) => p.theme.colors.gray6};
		font-size: 36px;
		font-weight: 600;
		line-height: 48px;
	}

	${media.md`
    ${(p) => css`
			p,
			a,
			li {
				font-size: ${p.theme.typography.sizes.t4};
				line-height: 24px;
			}

			p {
				margin: 20px 0;
			}

			h3 {
				margin: 24px 0 6px 0;
			}

			h2 {
				margin: 60px 0 24px 0;
				font-size: ${(p) => p.theme.typography.sizes.h4};
				line-height: 32px;
			}

			h3 {
				font-size: 22px;
				line-height: 28px;
				font-weight: 600;
			}

			h4 {
				font-size: ${(p) => p.theme.typography.sizes.h5};
				line-height: 28px;
				font-weight: 600;
			}

			figure {
				margin: 40px 0;
			}

			hr {
				margin-bottom: 40px;
			}
		`}
	`}

	${media.xl`
    ${(p) => css`
			max-width: initial;

			p,
			a,
			li {
				font-size: ${p.theme.typography.sizes.t4};
				line-height: 28px;
			}

			h2 {
				font-size: ${(p) => p.theme.typography.sizes.t6};
				line-height: 40px;
				margin: 70px 0 24px 0;
			}

			figure {
				margin: 60px 0;
			}

			hr {
				margin-bottom: 60px;
			}
		`}
	`};
`;
